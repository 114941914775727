<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
    content-class="import-user__dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-plus-box</v-icon>
        Import users
      </v-btn>
    </template>
    <v-card class="import-users__card pa-8 text-center">
      <v-card-title class="mb-5">
        <span class="text-title-dialog">Import users</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon dark @click="closeDialog">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <p class="text-subhead">
        1. Please use our template to add new users. You can download it
        <a href="/files/CSVtemplate.csv" download>here</a>
      </p>
      <p class="text-subhead">2. Fill it correctly</p>
      <div>
        <img src="@/assets/images/import_csv.png" alt="Import CSV" />
      </div>
      <p class="text-subhead">4. Upload file below:</p>

      <p class="text-subhead" v-if="csvFile == null">From CSV File</p>
      <p class="text-subhead" v-else>Press Import button to start process</p>

      <v-card-text class="file-input text-center">
        <v-file-input
          v-model="csvFile"
          label="CSV File"
          outlined
          prepend-icon=""
          @click:clear="clearCsvFile"
          accept=".csv"
          prepend-inner-icon="$vuetify.icons.addCsvIcon"
        />
      </v-card-text>
      <!-- <v-card-text v-if="!importOnce">
        <template v-for="header in headers">
          <v-select
            :key="header.source"
            :items="dests"
            :label="header.source"
            prepend-inner-icon="$vuetify.icons.serverIcon"
            outlined
            required
            @change="header.dest = $event"
          ></v-select>
        </template>
      </v-card-text> -->

      <v-card-text ref="importLog" v-if="importLogArray.length > 0">
        <ul class="import-log">
          <li v-for="(logString, i) in importLogArray" :key="i">
            <span>
              {{ logString }}
            </span>
          </li>
        </ul>
      </v-card-text>

      <v-card-actions class="card-actions__wrapper" v-if="!importOnce">
<!--        <v-btn-->
<!--          v-if="showSettings"-->
<!--          width="100%"-->
<!--          class="text-capitalize"-->
<!--          type="submit"-->
<!--          color="primary"-->
<!--          @click="uploadFile"-->
<!--          >Are you sure?-->
<!--        </v-btn>-->
        <v-btn
          width="100%"
          class="text-capitalize"
          type="submit"
          color="primary"
          @click="parseFile"
          :disabled="csvFile == null"
          >Import CSV File
        </v-btn>
      </v-card-actions>
      <v-card-actions class="card-actions__wrapper" v-if="done">
        <v-btn
          width="100%"
          class="text-capitalize"
          color="primary"
          @click="closeDialog"
          autofocus
        >OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import awsAuth from "@/cognito/aws-auth";
import Axious from "axios";

export default {
  props: {
    allGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      fileLines: [],
      dests: ["name", "company", "email"],
      headers: [],
      showSettings: false,
      csvFile: null,
      uploadOnce: false,
      importOnce: false,

      csv: [],
      importLogArray: [],
      importList: [],
      totalImportCount: 0,
      poolUsers: [],
      importedUsers: [],
      canExport: false,
      done: false,
    };
  },
  computed: {
    readyToUpload() {
      let ready = true;
      this.headers.map((header) => {
        ready = ready && header.dest;
      });
      return ready;
    },
  },
  mounted() {
    this.importList = [];
    this.totalImportCount = 0;
    this.importLogArray = [];
    this.importedUsers = [];
    this.canExport = false;
  },
  methods: {
    clearCsvFile() {
      this.done = false;
      this.importLogArray = [];
      this.importOnce = false;
      this.csvFile = null;
      this.headers = [];
      this.showSettings = !this.showSettings;
    },
    parseFile() {
      let self = this;
      this.uploadOnce = true;
      this.csvFile.text().then((fileContent) => {
        fileContent = fileContent.replaceAll(';',',').replaceAll("\r", "");
        this.fileLines = fileContent.split("\n");
        if (this.fileLines.length == 0) return;
        this.showSettings = true;
        let headers = this.fileLines[0].split(",");
        this.headers = [];
        for (var i = 0; i < headers.length; i++) {
          let header = headers[i];
          if (header == undefined || header.length == 0) continue;
          let autoMatch = this.dests.find(
            (x) => x.toLowerCase() == header.toLowerCase()
          );
          this.headers.push({
            source: header,
            dest: autoMatch != undefined ? autoMatch : null,
          });
        }
        self.uploadFile();
      });
    },
    onChangeDests(e) {
      console.log(e);
    },
    closeDialog() {
      this.dialog = false;
      this.csvFile = null;
      this.importLogArray = [];
      this.$emit("close");
    },
    uploadFile() {
      this.importOnce = true;
      let importSetup = {};
      for (var i = 0; i < this.headers.length; i++) {
        let header = this.headers[i];
        if (header.skip || header.dest == null || header.dest == undefined)
          continue;
        importSetup[header.source] = header.dest;
      }
      let headerLine = this.fileLines[0].split(",");
      let data = [];
      for (i = 1; i < this.fileLines.length; i++) {
        let fields = this.fileLines[i].split(",");
        let line = {};
        for (var j = 0; j < fields.length; j++) {
          let headerName = headerLine[j];
          if (importSetup[headerName] == undefined) continue;
          line[importSetup[headerName]] = fields[j].replace("\r", "");
        }
        if (line.name) {
          data.push(line);
        }
      }
      console.table(data);
      if (data.length > 0) {
        this.importAllUsers(data);
      } else {
        this.logln('⚠️ There is no any user to create in your file');
        this.done = true;
      }
    },
    importAllUsers(users) {
      this.importList = users;
      this.totalImportCount = this.importList.length;
      this.importedUsers = [];
      this.importLogArray = [];
      this.log("Getting all the users...");
      awsAuth
        .getAllUsers()
        .then((result) => {
          this.log("✅");
          this.poolUsers = result;
          this.importNextUser();
        })
        .catch((err) => {
          this.log(`❌ ${err.status}: ${err.message}`);
        });
    },
    importNextUser() {
      if (this.importList.length == 0) {
        this.logln("🙌 All done!");
        this.canExport = true;
        this.done = true;
        this.$emit("close");
        return;
      }
      let user = this.importList.shift();
      this.importUser(user);
    },

    importUser(user) {
      if (
        user.email == undefined ||
        user.email == null ||
        !this.validateEmail(user.email)
      ) {
        this.logln(`💥 Invalid email: '${user.email}', skipping`);
        this.importNextUser();
        return;
      }
      if (user.company == undefined || !this.allGroups.find(x => x == user.company) ) {
        user.company = 'nocompany';
      }
      // cleanup
      user.username = user.name.toLowerCase().replaceAll(" ", "_").substr(0, 15);
      var newName = user.username;

      this.importedUsers.push(user);
      this.logln(
        `[${this.importedUsers.length}/${this.totalImportCount}] Importing ${user.username} / ${user.email}... `
      );
      let userWithSameName = this.poolUsers.find(x => x.Username.substr(0, 15) == user.username);
      let userWithSameEmail = this.poolUsers.find(x => x.Attributes?.find(e => e.Name == 'email').Value == user.email);

      if (userWithSameName) {
        if (userWithSameName.Attributes.find(x => x.Name == 'email').Value == user.email) {
          this.logln("👤 existing user! ");
          if (userWithSameName.Attributes.find(x => x.Name == 'email_verified').Value == 'true') {
            this.log("Verified, skipping... ");
            this.importNextUser();
            return;

          } else {
            this.log(`Recreating user ${user.username}... `);
            awsAuth
              .deleteUser(user.username)
              .then(() => {
                this.poolUsers = this.poolUsers.filter(x => x.Username != user.username);
                awsAuth
                  .createUser({ ...user, family_name: user.company })
                  .then((resp) => {
                    this.log(`✅`);
                    this.$emit("addUser", user);
                    this.poolUsers.push(resp.data.data.User);
                    this.changeGroups(user);
                    return;
                  })
                  .catch((err) => {
                    this.log(`❌ ${err.status}: ${err.message}`);
                    this.importNextUser();
                    return;
                  });
              })
              .catch((err) => {
                this.log(`❌ ${err.status}: ${err.message}`);
                this.importNextUser();
                return;
              });
          }
        } else {
          if (userWithSameEmail) {
            if (userWithSameEmail.Attributes.find(x => x.Name == 'email_verified').Value == 'true') {
              this.logln(`This email is already registered and verified with username: ${userWithSameEmail.Username}`);
              this.importNextUser();
              return;
            } else {
              this.logln(`👤 existing email! Recreating user ${userWithSameEmail.Username}... `);


              while (this.poolUsers.find(x => x.Username == newName)) {
                this.logln(`⚠️️ ${newName} is already registered...`);
                newName += '1';
              }


              awsAuth
                .deleteUser(userWithSameEmail.Username)
                .then(() => {
                  this.poolUsers = this.poolUsers.filter(x => x.Username != userWithSameEmail.Username);

                  user.username = newName;
                  user.password = this.generatePassword(12);
                  awsAuth
                    .createUser({ ...user, family_name: user.company })
                    .then((resp) => {
                      this.log(`✅`);
                      this.$emit("addUser", user);
                      this.poolUsers.push(resp.data.data.User);
                      this.changeGroups(user);
                      return;
                    })
                    // .catch((err) => {
                    //   this.log(`❌ ${err.status}: ${err.message}`);
                    //   this.importNextUser();
                    //   return;
                    // });
                })
                .catch((err) => {
                  this.log(`❌ ${err.status}: ${err.message}`);
                  this.importNextUser();
                  return;
                });
            }
          } else {

            while (this.poolUsers.find(x => x.Username == newName)) {
              this.logln(`⚠️ ${newName} is already registered...`);
              newName += '1';
            }

            user.password = this.generatePassword(12);
            user.username = newName;
            this.logln("🆕 New user, signing up... ");
            awsAuth
              .createUser({ ...user, family_name: user.company })
              .then((resp) => {
                this.log(`✅`);
                // this.confirmUser(user);
                this.$emit("addUser", user);
                this.poolUsers.push(resp.data.data.User);
                this.changeGroups(user);
              })
              .catch((err) => {
                this.log(`❌ ${err.status}: ${err.message}`);
                this.importNextUser();
              });
          }
        }
      }
      else if (userWithSameEmail) {
        this.logln("👤 existing email! ");
        if (userWithSameEmail.Attributes.find(x => x.Name == 'email_verified').Value == 'true') {
          this.log('Verified email, skipping...');
          this.importNextUser();
          return;
        } else {
          this.log('Not verified, creating new user... ');

          while (this.poolUsers.find(x => x.Username == newName)) {
            this.logln(`⚠️️ ${newName} is already registered...`);
            newName += '1';
          }

          user.password = this.generatePassword(12);
          user.username = newName;
          awsAuth
            .deleteUser(userWithSameEmail.Username)
            .then(() => {
              this.poolUsers = this.poolUsers.filter(x => x.Username != userWithSameEmail.Username);

              awsAuth
                .createUser({ ...user, family_name: user.company })
                .then((resp) => {
                  this.log(`✅`);
                  this.$emit("addUser", user);
                  this.poolUsers.push(resp.data.data.User);
                  this.changeGroups(user);
                  return;
                })
                .catch((err) => {
                  this.log(`❌ ${err.status}: ${err.message}`);
                  this.importNextUser();
                  return;
                });
            })
            .catch((err) => {
              this.log(`❌ ${err.status}: ${err.message}`);
            });
        }
      } else {
        user.password = this.generatePassword(12);
        this.logln("🆕 New user, signing up... ");
        awsAuth
          .createUser({ ...user, family_name: user.company })
          .then((resp) => {
            this.log(`✅`);
            // this.confirmUser(user);
            this.$emit("addUser", user);
            this.poolUsers.push(resp.data.data.User);
            this.changeGroups(user);
          })
          .catch((err) => {
            this.log(`❌ ${err.status}: ${err.message}`);
            this.importNextUser();
          });
      }
    },

    confirmUser(user) {
      this.logln(`Confirming user auth ${user.username}... `);
      awsAuth
        .adminConfirmUser(user.username)
        .then(() => {
          this.log(`✅`);
          // Add user in local state
          this.$emit("addUser", user);
          this.changeGroups(user);
        })
        .catch((err) => {
          this.log(`❌ ${err.status}: ${err.message}`);
          this.changeGroups(user);
        });
    },
    changeGroups(user) {
      this.logln(`Changing user groups ${user.username}... `);
      this.log(`Guest... `);
      awsAuth
        .addUserToGroup(user.username, "Guest")
        .then(() => {
          this.log(`role registered...✅`);
          this.importNextUser();
        })
        .catch((err) => {
          this.log(`❌ ${err.status}: ${err.message}`);
          this.importNextUser();
        });
    },
    generatePassword(length) {
      let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    validateEmail(email) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    downloadCsvResults() {
      console.log("Here you go!");
      var resultCsv = "Username;Email;Company;Password\n";
      for (var i = 0; i < this.importedUsers.length; i++) {
        let user = this.importedUsers[i];
        resultCsv += `${user.username};${user.email};${user.company};${user.password}\n`;
      }
      console.log(resultCsv);

      Axious({
        url: "https://source.unsplash.com/random/500x500",
        method: "GET",
        responseType: "blob",
      }).then(() => {
        const url = window.URL.createObjectURL(new Blob(resultCsv.split("")));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "result.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    logln(text) {
      this.importLogArray.push(text);
      if (this.$refs.importLog) {
        this.$refs.importLog.scrollIntoView({ behavior: "smooth" });
      }
    },
    log(text) {
      if (this.importLogArray.length > 0) {
        var str = this.importLogArray.pop();
        str += text;
        this.importLogArray.push(str);
      } else {
        this.importLogArray.push(text);
      }
      if (this.$refs.importLog) {
        this.$refs.importLog.scrollIntoView({ behavior: "smooth" });
      }
    },

  },
  watch:{
    dialog(value) {
      if (value) {
        this.done = false;
        this.importOnce = false;
        this.showSettings = false;
        this.form = {};
        this.csvFile = null;
        this.importLogArray = [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .import-log {
    background-color: #f3f3f3;
    list-style-type: none;

    margin: 1rem;
    padding: 0;

    li {
      color: #2b2b2b;
      font-size: 16px;
      margin-bottom: 5px;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
  }

</style>
