<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card class="user-edit__card">
      <v-card-title class="pa-8">
        <v-btn color="red" text dark @click.stop="onClickDelete">
          <v-icon class="ma-2" color="red">mdi-close</v-icon>Delete User
        </v-btn>
        <v-btn :color="banned ? 'orange' : 'purple'" text dark @click="setBanStatus" v-if="statusLoad" :loading="banBtnDisabled">
          <v-icon class="ma-2" :color="banned ? 'orange' : 'purple'">mdi-cancel</v-icon>{{ !banned ? 'Ban User' : 'Unlock User' }}
        </v-btn>
        <v-dialog v-model="innerConfirmDelete" width="450">
          <v-card class="ban-user__card pa-4">
            <v-card-title class="headline">Delete user?</v-card-title>
            <v-card-text class="text-subhead"
              >You are trying to delete user. Are you sure?</v-card-text
            >
            <v-card-actions class="card-actions__wrapper">
              <v-btn
                id="card-action_cancelbtn"
                class="btn-cancel text-capitalize"
                @click.stop="innerConfirmDelete = false"
                >Cancel</v-btn
              >
              <v-btn
                id="card-action_submitbtn"
                class="btn-submit text-capitalize"
                color="red darken-1"
                @click.stop="deleteUser"
                >Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-8">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Full Name"
                v-model="form.name"
                outlined
                :rules="rules"
                hide-details="auto"
                color="blue"
                height="60px"
                prepend-inner-icon="$vuetify.icons.userDarkIcon"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Email"
                outlined
                :rules="rules"
                v-model="form.email"
                hide-details="auto"
                height="60px"
                disabled
                prepend-inner-icon="$vuetify.icons.emailDarkIcon"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="allGroups"
                v-model="form.group"
                label="Company"
                outlined
                prepend-inner-icon="$vuetify.icons.friendsIcon"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Username"
                outlined
                :rules="rules"
                v-model="form.username"
                disabled
                hide-details="auto"
                height="60px"
                prepend-inner-icon="$vuetify.icons.userDarkIcon"
              ></v-text-field>
            </v-col>

<!--          </v-row>-->
<!--            <v-col cols="12" sm="6" md="6">-->
<!--              <v-list-item to="/admin/access"-->
<!--                >Edit access rules&nbsp;<v-icon-->
<!--                  >mdi-square-edit-outline</v-icon-->
<!--                ></v-list-item-->
<!--              >-->
<!--            </v-col>-->
<!--          <v-row>-->

          </v-row>
        </v-container>
        <small></small>
      </v-card-text>
      <v-card-actions class="card-actions__wrapper pa-8 text-center">
        <v-btn id="action-submit" type="button" @click="onEditUser">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Constants } from "@/config/constants";

export default {

  props: {
    value: { type: Object, required: false },
    dialog: { type: Boolean, default: false },
    allGroups: { type: Array, default: () => [] },
    banned: { type: Boolean, default: false },
    banBtnDisabled: { type: Boolean, default: false },
    statusLoad: { type: Boolean, default: false }
  },
  data() {
    return {
      btnColor: 'purple',
      form: this.value,
      rules: [],
      allRoles: Constants.AllRoles,
      usersGroups: [],
      innerConfirmDelete: false,
    };
  },
  methods: {
    onEditUser() {
      this.$emit("input", this.form);
      this.$emit("edit");
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    onClickDelete() {
      this.innerConfirmDelete = true;
    },
    setBanStatus() {
      this.$emit("setBan", this.value.username, !this.banned);
    },
    deleteUser() {
      this.$emit("delete", this.value.username);
    },
  },
};
</script>
