<template>
  <v-dialog v-model="dialog" max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="red darken-1" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-minus-box</v-icon>
        <span v-if="region == 'company'">
          {{ removeusers.length > 1 ? "Remove users" : "Remove user" }}
        </span>
        <span v-else>
          {{ removeusers.length > 1 ? "Delete users" : "Delete user" }}
        </span>
      </v-btn>
    </template>
    <v-card class="ban-user__card pa-8">
      <v-card-title>
        <span>Remove {{ removeusers.length > 1 ? "users" : "user" }}?</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false" color="gray">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <p class="text-subhead" v-if="region == 'company'">
        {{
          removeusers.length > 1
            ? "Selected users will be removed from group. Are you sure?"
            : "Selected user will be removed from group. Are you sure?"
        }}
      </p>
      <p class="text-subhead" v-else>
        {{
          removeusers.length > 1
            ? "Selected users will be removed permanently. Are you sure?"
            : "Selected user will be removed permanently. Are you sure?"
        }}
      </p>

      <v-card-actions class="card-actions__wrapper">
        <v-btn
          id="card-action_cancelbtn"
          class="btn-cancel text-capitalize"
          @click.stop="dialog = false"
          >Cancel</v-btn
        >
        <v-btn
          id="card-action_submitbtn"
          class="btn-submit text-capitalize"
          color="red darken-1"
          @click="onClickDelete"
          >Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    removeusers: {
      type: Array,
    },
    region: {
      type: String,
      default: "company",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onClickDelete() {
      this.$emit("on-remove-users");
      this.dialog = false;
    },
  },
};
</script>
