<template>
  <div class="users-page-wrapper">
    <v-row class="users-header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>
      <div class="header-action">
        <remove-user-dialog
          v-if="selectedUsers.length > 0"
          :removeusers="selectedUsers"
          region="user"
          @on-remove-users="removeUsers"
        />
        <create-user-dialog
          v-model="createForm"
          :allGroups="allGroups"
          :showDialog="openDialog"
          :isUpdating="isLoading"
          @open-create-dialog="openCreateDialog"
          @create="createUser"
        />
        <import-users-dialog
          :allGroups="allGroups"
          @addUser="addUserTemp"
          @close="closeImportDialog"
        ></import-users-dialog>
      </div>
      <div class="header-search pl-5">
        <v-text-field
          v-model="search"
          clearable
          color="blur"
          solo
          dense
          hide-details
          clear-icon="mdi-close"
          prepend-inner-icon="mdi-magnify"
          label="Search for value"
          @input="searchInput"
        ></v-text-field>
      </div>
    </v-row>
    <v-data-table
      v-model="selectedUsers"
      :headers="headers"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      show-select
      item-key="username"
      hide-default-header
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
      }"
      @click:row="clickUser"
      class="data-table__users"
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Username</label>
                <v-select
                  v-model="sortby_username"
                  :items="sorting_order"
                  dense
                  hide-details
                  autowidth
                  class="select-fit"
                  @change="updateSorting('username', sortby_username)"
                >
                  <template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Company</label>
                <v-select
                  v-model="sortby_company"
                  :items="allGroups"
                  multiple
                  dense
                  hide-details
                  autowidth
                  :menu-props="{ maxHeight: '400' }"
                  class="select-fit"
                >
                  <template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Email</label>
                <v-select
                  v-model="sortby_email"
                  :items="sorting_order"
                  dense
                  hide-details
                  autowidth
                  @change="updateSorting('email', sortby_email)"
                  class="select-fit"
                >
                  <template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
            <th>
              <div class="th-wrapper">
                <label class="th-lb">Status</label>
                <v-select
                  v-model="sortby_status"
                  :items="sorting_order"
                  dense
                  hide-details
                  autowidth
                  @change="updateSorting('status', sortby_status)"
                  class="select-fit"
                >
                  <template v-slot:selection=""> </template>
                </v-select>
              </div>
            </th>
          </tr>
        </thead>
      </template>
    </v-data-table>

    <!-- EditUserDialog  -->
    <edit-user-dialog
      v-if="dialogEdit"
      v-model="editForm"
      :dialog="dialogEdit"
      :allGroups="allGroups"
      :status-load="editDialogStatusLoad"
      :banned="formUserBanned"
      :ban-btn-disabled="banBtnDisabled"
      @setBan="setBanStatus"
      @edit="editUser"
      @delete="deleteUser"
      @close-dialog="closeEditDialog"
    />
    <!-- End EditUserDialog -->

  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import { Constants } from "@/config/constants";
import AdminTabs from "../AdminTabs";
import CreateUserDialog from "./CreateUserDialog";
import EditUserDialog from "./EditUserDialog";
import RemoveUserDialog from "@/components/admin/company/dialogs/RemoveUserDialog";
import ImportUsersDialog from "./dialogs/ImportUsersDialog";
import { mapActions } from "vuex";

export default {
  components: {
    AdminTabs,
    CreateUserDialog,
    EditUserDialog,
    ImportUsersDialog,
    RemoveUserDialog,
  },
  data() {
    return {
      openDialog: false,
      filter: {},
      sortDesc: false,
      sortBy: "username",
      page: 1,
      itemsPerPage: 10,
      sortby_email: "",
      sortby_username: "",
      sortby_status: "",
      sortby_company: [],
      sorting_order: Constants.SortingOrder,
      headers: Constants.UserTableHeaders,
      companies: [],
      users: [],
      selectedUsers: [],
      servers: Constants.Servers,
      dialogEdit: false,
      search: "",
      errorMessage: null,
      isLoading: true,
      rules: [],
      allGroups: [],
      allRoles: Constants.AllRoles,
      createForm: {},
      editForm: {},
      formUserBanned: false,
      banBtnDisabled: false,
      editDialogStatusLoad: false
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    filteredItems() {
      return this.users.filter((u) => this.sortby_company.includes(u.group))
    },
  },
  mounted() {
    this.getAllGroups();
    this.reloadTable();

  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    closeNotify() {
      this.notify = false;
    },
    getAllGroups() {
      let self = this;
      awsAuth
        .getAllGroups()
        .then((resp) => {
          self.allGroups = resp.data.map((x) => x.Username);
          self.sortby_company = self.allGroups;
        })
        .catch((err) => {
          console.log("err = " + err);
        });
    },
    reloadTable() {
      let self = this;
      this.users = [];
      awsAuth
        .getAllUsers()
        .then((res) => {
          self.users = res.map((user) => {
            return {
              username: user.Username,
              name: user.Attributes.find((x) => x.Name == "name")?.Value,
              email: user.Attributes.find((x) => x.Name == "email")?.Value,
              status: prettyStatus(
                user.Enabled,
                user.UserStatus == "CONFIRMED"
              ),
              group: user.Attributes.find((x) => x.Name == "family_name")?.Value.toLowerCase(),
            };
          });
        })
        .catch((error) => {
          this.errorMessage = `${error.status}: ${error.message}`;
          this.users = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateSorting(value, order) {
      this.sortBy = value;
      this.sortDesc = order == "Ascending" ? false : true;
    },
    createUser() {
      let self = this;
      if (this.createForm.username.includes(" ")) {
        let temp = this.createForm.username.replace(" ", "_");
        this.createForm.username = temp;
      }

      let user = {
        username: this.createForm.username,
        nickname: this.createForm.nickname,
        email: this.createForm.email,
        email_verified: false,
        family_name: this.createForm.group ? this.createForm.group : 'nocompany',
        address: this.createForm.address,
      };
      this.isLoading = true;

      awsAuth
        .createUser(user)
        .then(() => {
          self.addUserToGroup(self.createForm.username, self.createForm.role ? self.createForm.role : 'guest');
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${self.createForm.username} created`,
          });
          self.openDialog = false;
          self.sortBy = 'status';
          // self.reloadTable();

          let usr = {
              username: user.username,
              name: user.nickname,
              email: user.email,
              status: ' ',
              group: user.family_name,
          }

          this.users.splice(0, 0, usr);
          self.createForm = {};
        })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: error.message,
            text: error.response,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteUser(username) {
      let self = this;

      awsAuth
        .deleteUser(username)
        .then(() => {
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Deleted",
          });
          self.users = self.users.filter((user) => user.username !== username);
        })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: error.message,
            text: error.response,
          });
        })
        .finally(() => {
          this.dialogEdit = false;
          this.isLoading = false;
          this.closeEditDialog();
        });
    },
    removeUsers() {
      let list = this.selectedUsers;
      Promise.all(
        list.map((user) => {
          this.deleteUser(user.username);
        })
      )
        .then(() => {
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Deleted successfully",
          });

          list.map((removed) => {
            this.users.filter((user) => user.username != removed.username);
          });
        })
        .finally(() => {
          this.selectedUsers = [];
        });
    },
    addUserToGroup(username, groupname) {
      let self = this;
      awsAuth
        .addUserToGroup(username, groupname)
        .then(() => {
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${username} is added to ${groupname}`,
          });
        })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: error.message,
            text: error.response,
          });
          self.isLoading = false;
        });
    },
    removeUserFromGroup(username, groupName) {
      awsAuth
        .removeUserFromGroup(username, groupName)
        .then(() => {
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${username} is removed from ${groupName}`,
          });
        })
        .catch((error) => {
          this.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: error.message,
            text: error.response,
          });
        });
    },

    setBanStatus(username, banned) {
      this.isLoading = true;
      this.banBtnDisabled = true;
      var request = banned ? awsAuth.addUserToGroup(username, "banned") : awsAuth.removeUserFromGroup(username, 'banned')
      request.then(() => {

        this.snackbar({
          color: banned ? "error" : "success",
          icon: banned ? "mdi-alert-circle-outline" : "mdi-check-circle",
          title: banned ? "" : "Success",
          text: banned ? `${username} is banned!` : `${username} is removed from banned`,
        });
        this.formUserBanned = banned;
      })
      .catch((err) => {
        this.snackbar({
          color: "error",
          icon: "mdi-alert-circle-outline",
          title: err.message,
          text: err.response,
        });
      })
      .finally(() => {
        this.isLoading = false;
        this.banBtnDisabled = false;
      });
    },
    updateUsersAttributes(username) {
      let self = this;
      awsAuth
        .adminUpdateAttributes(username, {
          name: self.editForm.name ? self.editForm.name : "",
          family_name: self.editForm.group,
        })
        .then(() => {
          self.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${self.editForm.username} updated`,
          });
          self.users = self.users.map((user) =>
            user.username === username
              ? {
                  ...user,
                  name: self.editForm.name,
                  group: self.editForm.group,
                }
              : user
          );
        })
        .catch((error) => {
          self.snackbar({
            color: "error",
            icon: "mdi-alert-circle-outline",
            title: error.message,
            text: error.response,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.dialogEdit = false;
          this.closeEditDialog();
        });
    },
    clickUser(item) {
      this.editDialogStatusLoad = false;
      this.getUsersGroups(item.username);
      this.dialogEdit = true;

      this.editForm.username = item.username;
      this.editForm.name = item.name;
      this.editForm.group = item.group;
      this.editForm.email = item.email;
    },
    getUsersGroups(username) {
      awsAuth.getUsersGroups(username)
        .then(result => {
          this.editDialogStatusLoad = true;
          this.formUserBanned = result.data.map(item => item.GroupName).includes('banned');
        })
    },
    editUser() {
      this.isLoading = true;
      this.updateUsersAttributes(this.editForm.username);
    },
    addUserTemp(user) {
      this.users.push(user);
    },
    closeEditDialog() {
      this.dialogEdit = false;
    },
    closeImportDialog() {
      this.isLoading = true;
      this.reloadTable();
    },
    openCreateDialog(value) {
      this.openDialog = value;
    },
    searchInput(value) {
      if (value) {
        this.search = value.replaceAll(' ', '_');
      }
    }
  },
};

function prettyStatus(isEnabled, isConfirmed) {
  return !isEnabled ? "❌" : isConfirmed ? "✔" : "❔";
}
</script>
<style scoped></style>
