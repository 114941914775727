<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-plus-box</v-icon>
        Create User
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-8">
        <span class="text-title-dialog">Create user</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-8">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Username"
                maxlength="15"
                v-model="form.username"
                outlined
                :rules="rules"
                color="blue"
                height="30px"
                prepend-inner-icon="$vuetify.icons.userDarkIcon"
                @input="usernameInput"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                ref="emailField"
                label="Email"
                outlined
                :rules="emailRules"
                v-model="form.email"
                height="30px"
                prepend-inner-icon="$vuetify.icons.emailDarkIcon"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="servers"
                label="Server"
                v-model="form.server"
                hide-details
                prepend-inner-icon="$vuetify.icons.serverIcon"
                outlined
                height="30px"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="allRoles"
                v-model="form.role"
                hide-details
                label="Role"
                outlined
                height="30px"
                prepend-inner-icon="$vuetify.icons.friendsIcon"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0" style="padding-top:30px;">
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="allGroups"
                v-model="form.group"
                label="Company"
                outlined
                height="30px"
                prepend-inner-icon="$vuetify.icons.buildingIcon"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Department"
                outlined
                :rules="rules"
                v-model="form.address"
                height="30px"
                prepend-inner-icon="$vuetify.icons.buildingIcon"
              ></v-text-field>
            </v-col>
<!--            <v-col cols="12" sm="6" md="6">-->
<!--              <v-list-item>-->
<!--                <v-list-item-action>-->
<!--                  <v-checkbox v-model="form.email_verified"></v-checkbox>-->
<!--                </v-list-item-action>-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>Mark email as verified?</v-list-item-title>-->
<!--                </v-list-item-content>-->
<!--              </v-list-item>-->
<!--            </v-col>-->
          </v-row>
        </v-container>
        <small></small>
      </v-card-text>
      <v-card-actions class="card-actions__wrapper px-8 py-4">
        <v-spacer></v-spacer>
        <v-btn
          id="action-submit"
          type="button"
          :loading="isUpdating"
          @click="onCreateUser"
          :disabled="!createButtonDisabled"
        >
          Create user
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Constants } from "@/config/constants";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
    allGroups: {
      type: Array,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      dialog: false,
      rules: [
        (value) => !!value || "Required",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      emailRules: [
        (value) => !!value || "Required",
        (value) => /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || "Please enter valid email",
      ],
      servers: Constants.Servers,
      allRoles: Constants.AllRoles,
    };
  },
  computed: {
    createButtonDisabled() {
      if (this.form.username && this.form.email) {
        return (this.form.username.length > 2  && this.$refs.emailField.validate())
      }
      return false
    },
  },
  mounted() {},
  methods: {
    onCreateUser() {
      this.$emit("input", this.form);
      this.$emit("create");
    },
    usernameInput(value) {
      this.form.username = value.replaceAll(' ', '_')
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.form = {};
      }
      this.dialog = value;
    },
    dialog(value) {
      this.$emit('open-create-dialog', value);
    },
  },
};
</script>

<style></style>
